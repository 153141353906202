import { selectors, actions, } from '@ezugi/bootstrap';
import {
  filter, prop, values, mapObjIndexed,
} from 'ramda';

import { currentBetsSelector, } from '../../../selectors/bets';
import { getTotalBet, } from '.';

const { socketActions, notificationActions, betActions, } = actions;
const { userSelector, tableIdSelector, roundIdSelector, gameSelector, } = selectors;

export const buildParams = (bets, state) => {
  const user = userSelector(state);

  return {
    ClientId: user.clientId,
    ClientIP: user.clientIp,
    CurrentPlayerToken: user.currentPlayerToken,
    MessageType: 'PlaceBet',
    destination: 'player',
    gameType: gameSelector(state).gameId,
    TableId: tableIdSelector(state).toString(),
    roundId: roundIdSelector(state),
    BetsList: bets,
  };
};

const keepValidBets = filter(prop('valid'));

export const createBetsList = (bets) => ({
  betsList: bets,
});
export const createPlaceBetRequestPayload = (state) => {
  const currentBetsList = currentBetsSelector(state);
  const _actions = [];

  const currentBets = values(currentBetsList);

  if (currentBets.length) {
    const validBetsList = keepValidBets(currentBetsList);
    const totalBet = getTotalBet(validBetsList);

    const validBetsCount = values(validBetsList).length;

    if (currentBets.length > validBetsCount) {
      // Remove invalid bets and update `TotalBet` and `BetHistory`
      _actions.push(notificationActions.notification.add({ message: 'notifications.bet_below_min_limits_removed', }));
      _actions.push(betActions.history.apply({ current: validBetsList, }));
      _actions.push(betActions.totalBet.set({ value: totalBet, }));
    }

    // Cache valid bets for `Rebet`
    if (validBetsCount) {
      _actions.push(betActions.bet.cache({ current: validBetsList, totalBet, }));
    }

    const bets = mapObjIndexed((value, key) => ({
      betValue: value.value,
      index: key,
    }), validBetsList);

    const params = buildParams(values(bets), state);
    _actions.push(socketActions.socket.send(params));
  }

  return _actions;
};
