import { handleActions, } from 'redux-actions';
import { actions, } from '@ezugi/bootstrap';
import { mergeWith, } from 'ramda';
import { getTotalBet, } from '../../epics/bets/utils';

const { betActions, } = actions;

const addValues = (a, b) => ({ ...a, ...b, value: +(a.value + b.value).toFixed(2), });

export const INITIAL_STATE = {
  current: {},
  totalBet: 0,
};

INITIAL_STATE.history = [ { ...INITIAL_STATE, }, ];

export default handleActions(
  {
    [betActions.bet.set]: ({ history, ...state }, { payload: { betIndex, value, ...rest }, }) => {
      const current = mergeWith(addValues, state.current, { [betIndex]: { value, ...rest, }, });
      const t = getTotalBet(current);

      return {
        ...state,
        current,
        history: [ ...history, { ...state, current, totalBet: t, }, ],
      };
    },
    [betActions.bet.reset]: (state) => ({
      ...state,
      ...INITIAL_STATE,
    }),
    [betActions.bet.clear]: (state) => ({
      ...state,
      ...INITIAL_STATE,
      history: [ ...state.history, INITIAL_STATE, ],
    }),
    [betActions.bet.cache]: (state, { payload, }) => ({
      ...state,
      last: payload,
    }),
    [betActions.bet.apply]: (state, { payload, }) => ({
      ...state,
      ...payload,
      history: [ ...state.history, payload, ],
    }),
    [betActions.history.reset]: (state) => ({
      ...state,
      history: [ { ...INITIAL_STATE, }, ],
    }),
    [betActions.history.apply]: (state, { payload, }) => ({
      ...state,
      ...payload,
    }),
    [betActions.totalBet.set]: (state, { payload: { value, }, }) => ({
      ...state,
      totalBet: value,
    }),
  },
  INITIAL_STATE
);
