export const SMALL = 100;
export const BIG = 101;

// Combination bets
export const COMBINATION_1_2 = 612;
export const COMBINATION_1_3 = 613;
export const COMBINATION_1_4 = 614;
export const COMBINATION_1_5 = 615;
export const COMBINATION_1_6 = 616;
export const COMBINATION_2_3 = 623;
export const COMBINATION_2_4 = 624;
export const COMBINATION_2_5 = 625;
export const COMBINATION_2_6 = 626;
export const COMBINATION_3_4 = 634;
export const COMBINATION_3_5 = 635;
export const COMBINATION_3_6 = 636;
export const COMBINATION_4_5 = 645;
export const COMBINATION_4_6 = 646;
export const COMBINATION_5_6 = 656;

// Single bets
export const SINGLE_1 = 701;
export const SINGLE_2 = 702;
export const SINGLE_3 = 703;
export const SINGLE_4 = 704;
export const SINGLE_5 = 705;
export const SINGLE_6 = 706;

// Double bets
export const DOUBLE_1 = 501;
export const DOUBLE_2 = 502;
export const DOUBLE_3 = 503;
export const DOUBLE_4 = 504;
export const DOUBLE_5 = 505;
export const DOUBLE_6 = 506;

// Triple bets
export const TRIPLE_1 = 401;
export const TRIPLE_2 = 402;
export const TRIPLE_3 = 403;
export const TRIPLE_4 = 404;
export const TRIPLE_5 = 405;
export const TRIPLE_6 = 406;

// Any triple bet
export const ANY_TRIPLE = 400;

// Total bets
export const TOTAL_4 = 304;
export const TOTAL_5 = 305;
export const TOTAL_6 = 306;
export const TOTAL_7 = 307;
export const TOTAL_8 = 308;
export const TOTAL_9 = 309;
export const TOTAL_10 = 310;
export const TOTAL_11 = 311;
export const TOTAL_12 = 312;
export const TOTAL_13 = 313;
export const TOTAL_14 = 314;
export const TOTAL_15 = 315;
export const TOTAL_16 = 316;
export const TOTAL_17 = 317;
