import {
  socketEpic, betsEpic, betsReducer, payoutsEpic,
} from './store';
import {
  isHandheld,
  betToolbarClassName,
  mobileGameUIClassName,
} from './utils';
import { resultMessageClassName, } from './components/ResultDisplay/style';

const noComponent = () => Promise.resolve({ default: () => null, });

export default () => ({
  components: {
    GameBoard: () => import('./views/GameBoard'),
    WelcomeDialog: () => import('./components/WelcomeDialog'),
    VideoOverlay: () => import('./components/VideoOverlay'),
    ProgressBar: noComponent,
    Payouts: () => import('./components/Payouts'),
    GameInfo: () => import('./components/GameInfo'),
    ResultDisplay: () => import('./components/ResultDisplay'),
    Statistics: () => import('./views/MobileStatistics'),
  },
  store: {
    epics: [ socketEpic, betsEpic, payoutsEpic, ],
    reducers: { bets: betsReducer, },
  },
  props: {
    BetToolbar: {
      className: isHandheld && betToolbarClassName,
    },
    ResultMessage: {
      className: isHandheld && resultMessageClassName,
    },
    BoardToggle: {
      className: isHandheld && mobileGameUIClassName,
    },
  },
  gameBoardHeight: 33,
  desktopBoardToggle: true,
  landscapeToolbarOrientation: 'horizontal',
  hideLeftSideDrawer: true,
});
