import styled, { css, } from 'react-emotion';
import { SIZES, } from '@ezugi/constants';

export const Container = styled.div`
  padding: 40px 40px 20px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 280px;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.72) 17.56%,
    #000000 51.53%,
    rgba(0, 0, 0, 0.78) 83.5%,
    rgba(0, 0, 0, 0) 100%
  );

  > svg {
    filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.7));
    margin: 0 10px;
  }

  > svg:first-child {
    transform: rotate(-15deg);
  }

  > svg:nth-child(2) {
    transform: translateY(-50%);
  }

  > svg:last-child {
    transform: rotate(15deg);
  }
`;

export const mobileClassName = css`
  @media all and (orientation: portrait) {
    margin-top: ${SIZES.APPBAR_PORTRAIT_HEIGHT}px;
  }
`;

export const resultMessageClassName = css`
  top: -${SIZES.APPBAR_PORTRAIT_HEIGHT}px;
  height: calc(100% + ${SIZES.APPBAR_PORTRAIT_HEIGHT}px);
`;
