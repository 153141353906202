import { of, } from 'rxjs';

import { actions, } from '@ezugi/bootstrap';
import { GAME_RESULT, } from '@ezugi/constants';

import { takeLast, } from 'ramda';
import { gameResultsSelector, } from '../../selectors/game';

const {
  roundActions: { round, },
  gameActions: { game, },
} = actions;

export default function handleGameResult(socketMessage, state) {
  const { roundId, timeStamp, winningAmount, GameResults, Stats, LastWinners, } = socketMessage;
  const gameResults = gameResultsSelector(state);

  return of(
    game.set({
      gameResults: { ...gameResults, ...GameResults, },
      lastWin: winningAmount,
      stats: Stats,
      history: takeLast(500, (state.game.history || []).concat(GameResults)),
    }),
    round.set({
      roundStatus: GAME_RESULT,
      roundId,
      winAmount: winningAmount,
      winnerList: LastWinners,
      timestamp: timeStamp,
    }),
  );
}
