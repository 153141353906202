import bowser from 'bowser';
import { css, } from 'react-emotion';
import { SIZES, } from '@ezugi/constants';

export const isHandheld = bowser.mobile || bowser.tablet;

export const betToolbarClassName = css`
  @media all and (orientation: portrait) {
    z-index: 1;
  }
`;

export const mobileGameUIClassName = css`
  @media all and (orientation: landscape) {
    height: calc(100% - ${SIZES.APPBAR_LANDSCAPE_HEIGHT}px);
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: start;
  }
`;
