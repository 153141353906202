import { selectors, actions, constants, } from '@ezugi/bootstrap';
import { PLACE_YOUR_BETS, LAST_BETS, } from '@ezugi/constants';
import {
  reduce,
  contains,
  clamp,
  last,
  map,
  evolve,
  all,
  prop,
  mapObjIndexed,
  compose,
  flatten,
  values,
} from 'ramda';
import {
  SMALL,
  BIG,
  COMBINATION_1_2,
  COMBINATION_1_3,
  COMBINATION_1_4,
  COMBINATION_1_5,
  COMBINATION_1_6,
  COMBINATION_2_3,
  COMBINATION_2_4,
  COMBINATION_2_5,
  COMBINATION_2_6,
  COMBINATION_3_4,
  COMBINATION_3_5,
  COMBINATION_3_6,
  COMBINATION_4_5,
  COMBINATION_4_6,
  COMBINATION_5_6,
  SINGLE_1,
  SINGLE_2,
  SINGLE_3,
  SINGLE_4,
  SINGLE_5,
  SINGLE_6,
  DOUBLE_1,
  DOUBLE_2,
  DOUBLE_3,
  DOUBLE_4,
  DOUBLE_5,
  DOUBLE_6,
  TRIPLE_1,
  TRIPLE_2,
  TRIPLE_3,
  TRIPLE_4,
  TRIPLE_5,
  TRIPLE_6,
  ANY_TRIPLE,
  TOTAL_4,
  TOTAL_5,
  TOTAL_6,
  TOTAL_7,
  TOTAL_8,
  TOTAL_9,
  TOTAL_10,
  TOTAL_11,
  TOTAL_12,
  TOTAL_13,
  TOTAL_14,
  TOTAL_15,
  TOTAL_16,
  TOTAL_17,
} from '../betTypes';
import { STATUS, } from '../constants';
import { INITIAL_STATE, } from '../../../reducers/bets';

import { currentBetsSelector, lastBetsSelector, } from '../../../selectors/bets';

const { betActions, } = actions;

// eslint-disable-next-line
const {
  userBalanceSelector,
  totalBetSelector,
  generalConfigSelector,
  currentLimitsSelector,
  betsSelector,
} = selectors;
const {
  dialogActions: { dialog, },
  notificationActions: { notification, },
} = actions;

const { DIALOG: { LOW_BALANCE_DIALOG, } = {}, } = constants;
const RESULT_SEED = { ok: true, valid: true, status: STATUS.VALID, };
const statusPriorityMap = {
  [STATUS.BETTING_NOT_ALLOWED]: 5,
  [STATUS.NOT_ALLOWED]: 4,
  [STATUS.INVALID_BALANCE]: 3,
  [STATUS.OVER_INDEX_LIMIT]: 2,
  [STATUS.OVER_TABLE_LIMIT]: 2,
  [STATUS.BELOW_INDEX_LIMIT]: 1,
  [STATUS.BELOW_TABLE_LIMIT]: 1,
  [STATUS.VALID]: 0,
};

const getSimpleNotificationPayload = ({
  status,
  value,
  betAutoAdjust,
  maxTableLimit,
  maxBet,
  minBet,
}) => {
  const payload = (() => {
    switch (status) {
    case STATUS.NOT_ALLOWED:
      return { message: 'notifications.bet_not_allowed', };
    case STATUS.INVALID_BALANCE:
      return { message: 'messages.low_balance_message', };
    case STATUS.OVER_TABLE_LIMIT:
      return value
        ? { message: 'notifications.bet_over_table_max_limit_adjusted', }
        : {
          message: 'notifications.bet_over_table_max_limit',
          variables: { maxTableLimit, },
        };
    case STATUS.OVER_INDEX_LIMIT:
      return betAutoAdjust ? {
        autoAdjust: true,
        // eslint-disable-next-line
        event_name: STATUS.OVER_INDEX_LIMIT,
        message: 'notifications.bet_over_index_max_limit_adjusted',
        variables: { maxIndexLimit: maxBet, },
      } : {
        message: 'notifications.bet_over_table_max_limit',
        variables: { maxTableLimit, },
      };
    case STATUS.BELOW_INDEX_LIMIT:
      return betAutoAdjust
        ? {
          autoAdjust: true,
          // eslint-disable-next-line
          event_name: STATUS.BELOW_INDEX_LIMIT,
          message: 'notifications.bet_below_index_min_limit_adjusted',
        }
        : {
          message: 'notifications.bet_below_index_min_limit',
          variables: { minIndexLimit: minBet, },
        };
    default:
      return null;
    }
  })();
  return payload;
};

const getGroupNotificationPayload = ({ status, }) => {
  const payload = (() => {
    switch (status) {
    case STATUS.NOT_ALLOWED:
      return { message: 'notifications.bet_not_allowed', };
    case STATUS.INVALID_BALANCE:
      return { message: 'messages.low_balance_message', };
    case STATUS.OVER_INDEX_LIMIT:
    case STATUS.OVER_TABLE_LIMIT:
      return { message: 'notifications.group_bet_over_max_limits', };
    case STATUS.BELOW_INDEX_LIMIT:
    case STATUS.BELOW_TABLE_LIMIT:
      return { message: 'notifications.group_bet_below_min_limits', };
    default:
      return null;
    }
  })();

  return payload;
};

const pipeValidations = (validations) => (bet, state) => reduce(
  (res, fn) => (!res.valid ? res : fn(res.bet, state)),
  {
    ...RESULT_SEED,
    bet,
  },
  validations
);

export const getBetTypeLimits = (() => {
  const cache = {};
  let currentLimits;

  return (betIndex, state) => {
    if (cache[betIndex]) {
      return cache[betIndex];
    }

    currentLimits = currentLimits || currentLimitsSelector(state);

    const limits = { min: 0, max: 0, };

    switch (betIndex) {
    case SMALL:
    case BIG: {
      limits.min = currentLimits.Min_Small_Big;
      limits.max = currentLimits.Max_Small_Big;
      break;
    }
    case COMBINATION_1_2:
    case COMBINATION_1_3:
    case COMBINATION_1_4:
    case COMBINATION_1_5:
    case COMBINATION_1_6:
    case COMBINATION_2_3:
    case COMBINATION_2_4:
    case COMBINATION_2_5:
    case COMBINATION_2_6:
    case COMBINATION_3_4:
    case COMBINATION_3_5:
    case COMBINATION_3_6:
    case COMBINATION_4_5:
    case COMBINATION_4_6:
    case COMBINATION_5_6: {
      limits.min = currentLimits.Min_Combination;
      limits.max = currentLimits.Max_Combination;
      break;
    }
    case SINGLE_1:
    case SINGLE_2:
    case SINGLE_3:
    case SINGLE_4:
    case SINGLE_5:
    case SINGLE_6: {
      limits.min = currentLimits.Min_Single_Bet;
      limits.max = currentLimits.Max_Single_Bet;
      break;
    }
    case DOUBLE_1:
    case DOUBLE_2:
    case DOUBLE_3:
    case DOUBLE_4:
    case DOUBLE_5:
    case DOUBLE_6: {
      limits.min = currentLimits.Min_Double;
      limits.max = currentLimits.Max_Double;
      break;
    }
    case TRIPLE_1:
    case TRIPLE_2:
    case TRIPLE_3:
    case TRIPLE_4:
    case TRIPLE_5:
    case TRIPLE_6: {
      limits.min = currentLimits.Min_Specific_Triple;
      limits.max = currentLimits.Max_Specific_Triple;
      break;
    }
    case ANY_TRIPLE: {
      limits.min = currentLimits.Min_Any_Triple;
      limits.max = currentLimits.Max_Any_Triple;
      break;
    }
    case TOTAL_4:
    case TOTAL_17: {
      limits.min = currentLimits.Min_Total_4_17;
      limits.max = currentLimits.Max_Total_4_17;
      break;
    }
    case TOTAL_5:
    case TOTAL_16: {
      limits.min = currentLimits.Min_Total_5_16;
      limits.max = currentLimits.Max_Total_5_16;
      break;
    }
    case TOTAL_6:
    case TOTAL_15: {
      limits.min = currentLimits.Min_Total_6_15;
      limits.max = currentLimits.Max_Total_6_15;
      break;
    }
    case TOTAL_7:
    case TOTAL_14: {
      limits.min = currentLimits.Min_Total_7_14;
      limits.max = currentLimits.Max_Total_7_14;
      break;
    }
    case TOTAL_8:
    case TOTAL_13: {
      limits.min = currentLimits.Min_Total_8_13;
      limits.max = currentLimits.Max_Total_8_13;
      break;
    }
    case TOTAL_9:
    case TOTAL_12: {
      limits.min = currentLimits.Min_Total_9_12;
      limits.max = currentLimits.Max_Total_9_12;
      break;
    }
    case TOTAL_10:
    case TOTAL_11: {
      limits.min = currentLimits.Min_Total_10_11;
      limits.max = currentLimits.Max_Total_10_11;
      break;
    }
    default:
      break;
    }

    return limits;
  };
})();

export const doubleBet = (state) => mapObjIndexed((b, key) => {
  const { min, } = getBetTypeLimits(key, state);
  const value = b.value * 2;
  const valid = value >= min;
  return { ...b, value, valid, };
});

export const doubleBets = (state) => compose(
  evolve({
    current: doubleBet(state),
    totalBet: (t) => t * 2,
  }),
  betsSelector
)(state);

export const validateRoundStatus = (bet, { round: { roundStatus, }, }) => {
  const ok = contains(roundStatus, [ PLACE_YOUR_BETS, LAST_BETS, ]);
  return {
    ok,
    valid: ok,
    status: ok ? STATUS.VALID : STATUS.BETTING_NOT_ALLOWED,
    bet,
    actions: [],
  };
};

export const validateBalance = (bet, state) => {
  const balance = userBalanceSelector(state);
  const totalBet = totalBetSelector(state);

  const ok = balance - totalBet >= bet.value;
  const status = ok ? STATUS.VALID : STATUS.INVALID_BALANCE;

  return {
    ok,
    valid: ok,
    status,
    bet,
    actions: ok ? [] : [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ],
  };
};

export const validateLimits = (bet, state) => {
  const balance = userBalanceSelector(state);
  // bet limits
  const currentBets = currentBetsSelector(state);
  const { min: minBet, max: maxBet, } = getBetTypeLimits(bet.betIndex, state);
  const existingBet = (currentBets[bet.betIndex] || {}).value || 0;

  const { betAutoAdjust, } = generalConfigSelector(state);
  let status = bet.value + existingBet < minBet
    ? STATUS.BELOW_INDEX_LIMIT
    : bet.value + existingBet > maxBet
      ? STATUS.OVER_INDEX_LIMIT
      : STATUS.VALID;

  let ok = status === STATUS.VALID;
  const diff = maxBet - existingBet;

  let value = ok
    ? bet.value
    : clamp(
      betAutoAdjust
        ? minBet - existingBet
        : bet.value > diff
          ? diff
          : bet.value,
      diff,
      bet.value
    );

  // table limits
  const { Max_Bet: maxTableLimit, } = currentLimitsSelector(state);
  const totalBet = totalBetSelector(state);

  if (totalBet + value > maxTableLimit) {
    status = STATUS.OVER_TABLE_LIMIT;
    ok = false;
    value = maxTableLimit - totalBet;
  }

  if (value > balance - totalBet) {
    status = STATUS.INVALID_BALANCE;
    ok = false;
    value = 0;
  }

  const valid = !contains(status, [
    STATUS.INVALID_BALANCE,
    STATUS.BETTING_NOT_ALLOWED,
  ]);

  const _actions = [
    ...(status === STATUS.INVALID_BALANCE
      ? [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ]
      : [
        notification.add(
          getSimpleNotificationPayload({
            status,
            value,
            betAutoAdjust,
            minBet,
            maxBet,
            maxTableLimit,
          })
        ),
      ]),
  ];

  return {
    ok,
    valid,
    status,
    bet: {
      ...(value && {
        ...bet,
        value,
        valid: !(
          status === STATUS.BELOW_INDEX_LIMIT
          && !betAutoAdjust
          && value + existingBet < minBet
        ),
      }),
    },
    actions: _actions,
  };
};

export function validateBetUndo(_, state) {
  const h = [ ...state.bets.history, ];
  h.pop();
  const s = last(h) || INITIAL_STATE;
  const b = userBalanceSelector(state);

  const ok = s.totalBet <= b;

  return ok
    ? { ok, actions: [ betActions.history.apply({ ...s, history: h, }), ], }
    : { ok, actions: [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ], };
}

export const validateBet = pipeValidations([
  validateRoundStatus,
  validateBalance,
  validateLimits,
]);
const isOk = prop('ok');

export function validateBets(_bets, state) {
  const bets = mapObjIndexed((value, key) => ({
    betIndex: +key,
    ...value,
  }))(_bets);

  const results = map((bet) => validateBet(bet, state))(bets);
  const ok = all(isOk, values(results));

  const status = compose(
    reduce((prev, next) => {
      const prevPriority = statusPriorityMap[prev];
      const nextPriority = statusPriorityMap[next];

      return nextPriority > prevPriority ? next : prev;
    }, STATUS.VALID),
    map(prop('status'))
  )(values(results));

  return {
    ok,
    status,
    actions: compose(
      flatten,
      map(({ actions: _actions, }) => _actions),
      values
    )(results),
  };
}

export function validateRebet(_, state) {
  const lb = lastBetsSelector(state);
  const b = userBalanceSelector(state);

  const ok = lb.totalBet <= b;

  return ok
    ? {
      ok,
      actions: [ betActions.bet.apply(lb), ],
    }
    : {
      ok,
      actions: [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ],
    };
}

export function validateDouble(_, state) {
  const current = currentBetsSelector(state);
  const tb = totalBetSelector(state);
  const b = userBalanceSelector(state);

  const status = tb * 2 > b ? STATUS.INVALID_BALANCE : STATUS.VALID;
  const ok = status === STATUS.VALID;
  const _actions = ok ? [] : [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ];

  let result = {
    status,
    ok,
    valid: ok,
    actions: _actions,
  };

  if (status === STATUS.VALID) {
    result = validateBets(current, state);
    result.actions = result.ok
      ? [ betActions.bet.apply(doubleBets(state)), ]
      : [
        notification.add(
          getGroupNotificationPayload({
            status: result.status,
          })
        ),
      ];
    result.valid = result.ok;
  }

  return result;
}
