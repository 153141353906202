import { of, } from 'rxjs';
import { LOADING, } from '@ezugi/constants';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import { ls, } from '@ezugi/utils';
import { NO_WELCOME_DIALOG, WELCOME_DIALOG, } from '../../../constants/strings';

const {
  videoActions: { video, },
  roundActions: { round, },
  gameActions: { game, },
  settingsActions: { settings, },
  socketActions: { socket, },
  betActions: { history, bet, },
  dialogActions: { dialog, },
} = bootstrapActions;

const [ err, noWelcomeDialog, ] = ls.get(NO_WELCOME_DIALOG);

export default (socketMessage) => {
  const {
    VideoStreamData,
    roundId,
    timeStamp,
    RoundTripStartTime,
    playerBetOfThisRound,
    History,
    Stats,
  } = socketMessage;

  return of(
    socket.success(),
    settings.init(),
    video.set({ streamData: VideoStreamData, }),
    round.set({
      roundStatus: LOADING,
      roundId,
      roundTime: RoundTripStartTime,
      timestamp: timeStamp,
    }),
    bet.apply(
      playerBetOfThisRound.reduce(
        (acc, { betValue, index, }) => {
          acc.current[index] = {
            value: betValue,
            valid: true,
          };
          acc.totalBet += betValue;

          return acc;
        },
        {
          totalBet: 0,
          current: {},
        }
      )
    ),
    game.set({
      history: History,
      stats: Stats,
    }),
    history.reset(),
    ...(err || !noWelcomeDialog ? [ dialog.add({ name: WELCOME_DIALOG, }), ] : []),
  );
};
