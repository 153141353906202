import * as BET_TYPES from '../bets/betTypes';
import {
  SMALL,
  BIG,
  DOUBLE,
  TRIPLE,
  ANY_TRIPLE,
  TOTAL_4_OR_17,
  TOTAL_5_OR_16,
  TOTAL_6_OR_15,
  TOTAL_7_OR_14,
  TOTAL_8_OR_13,
  TOTAL_9_OR_12,
  TOTAL_10_OR_11,
  COMBINATION,
} from '../../../constants/strings';

export const payoutsIndexMap = {
  [BET_TYPES.SMALL]: SMALL,
  [BET_TYPES.BIG]: BIG,
  [BET_TYPES.DOUBLE_1]: DOUBLE,
  [BET_TYPES.TRIPLE_1]: TRIPLE,
  [BET_TYPES.ANY_TRIPLE]: ANY_TRIPLE,
  [BET_TYPES.TOTAL_4]: TOTAL_4_OR_17,
  [BET_TYPES.TOTAL_5]: TOTAL_5_OR_16,
  [BET_TYPES.TOTAL_6]: TOTAL_6_OR_15,
  [BET_TYPES.TOTAL_7]: TOTAL_7_OR_14,
  [BET_TYPES.TOTAL_8]: TOTAL_8_OR_13,
  [BET_TYPES.TOTAL_9]: TOTAL_9_OR_12,
  [BET_TYPES.TOTAL_10]: TOTAL_10_OR_11,
  [BET_TYPES.COMBINATION_1_2]: COMBINATION,
};

const DEFAULT_PAYOUT_OPTION = 'option1';

function getPayoutObj(p, payouts) {
  const customOptions = {
    [BET_TYPES.SINGLE_1]: DEFAULT_PAYOUT_OPTION,
    [BET_TYPES.SINGLE_2]: 'option2',
    [BET_TYPES.SINGLE_3]: 'option3',
  };

  const payoutOption = customOptions[+p.IndexID] || DEFAULT_PAYOUT_OPTION;

  return {
    index: +p.IndexID,
    payout: `${payouts[payoutOption]}:1`,
    name: payoutsIndexMap[p.IndexID],
  };
}

export function formatPayouts(list = []) {
  return list.reduce(
    (acc, p) => {
      const payouts = JSON.parse(p.payout);
      return { ...acc, [+p.IndexID]: getPayoutObj(p, payouts), };
    }
  );
}
