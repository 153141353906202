import {
  flatMap, pluck, filter, map,
} from 'rxjs/operators';
import { ofType, combineEpics, } from 'redux-observable';

import {
  INITIAL_DATA, WON_BETS, GAME_RESULT, PLACE_YOUR_BETS,
} from '@ezugi/constants';
import { actions, } from '@ezugi/bootstrap';

import handleInitialData from './handleInitialData';
import handleWonBets from './handleWonBets';
import handleGameResult from './handleGameResult';

const {
  socketActions: { socket, },
  uiActions: { progressBar, },
} = actions;

function initialDataEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === INITIAL_DATA),
    flatMap((socketMessage) => handleInitialData(socketMessage, state$.value))
  );
}

function wonBetsEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === WON_BETS),
    flatMap((socketMessage) => handleWonBets(socketMessage, state$.value))
  );
}

function gameResultEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === GAME_RESULT),
    flatMap((socketMessage) => handleGameResult(socketMessage, state$.value))
  );
}

function gameResultTotalEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === GAME_RESULT),
    map(({ GameResults, }) => {
      const { DiceOne, DiceTwo, DiceThree, } = GameResults;

      return progressBar.set({
        label: `${DiceOne}, ${DiceTwo}, ${DiceThree} TOTAL: ${DiceOne + DiceTwo + DiceThree}`,
      });
    })
  );
}

function placeYourBetsTotalEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === PLACE_YOUR_BETS),
    map(() => progressBar.reset())
  );
}

export default combineEpics(initialDataEpic, wonBetsEpic, gameResultEpic, gameResultTotalEpic, placeYourBetsTotalEpic);
