export const SMALL = 'SmallBig';
export const BIG = 'SmallBig';
export const DOUBLE = 'Double';
export const TRIPLE = 'Triple';
export const ANY_TRIPLE = 'AnyTriple';
export const TOTAL_4_OR_17 = 'Total4or17';
export const TOTAL_5_OR_16 = 'Total5or16';
export const TOTAL_6_OR_15 = 'Total6or15';
export const TOTAL_7_OR_14 = 'Total7or14';
export const TOTAL_8_OR_13 = 'Total8or13';
export const TOTAL_9_OR_12 = 'Total9or12';
export const TOTAL_10_OR_11 = 'Total10or11';
export const COMBINATION = 'Combination';
export const SINGLES = 'Singles';
export const SIMPLE_SINGLE = 'SimpleSingle';
export const SIMPLE_DOUBLE = 'SimpleDouble';
export const SIMPLE_TRIPLE = 'SimpleTriple';

export const NO_WELCOME_DIALOG = 'NO_WELCOME_DIALOG_SICBO';
export const WELCOME_DIALOG = 'WelcomeDialog';
