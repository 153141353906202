import { createSelector, } from 'reselect';
import { propOr, compose, reverse, } from 'ramda';
import { selectors, } from '@ezugi/bootstrap';

const { gameSelector, } = selectors;

export const gameResultsSelector = createSelector(
  gameSelector,
  propOr({}, 'gameResults')
);

export const gameHistorySelector = createSelector(
  gameSelector,
  compose(
    reverse,
    propOr([], 'history')
  )
);

export const gameStatsSelector = createSelector(
  gameSelector,
  propOr({}, 'stats')
);
