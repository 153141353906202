import { of, } from 'rxjs';
import { actions, } from '@ezugi/bootstrap';

import { gameResultsSelector, } from '../../selectors/game';

const {
  gameActions: { game, },
} = actions;

export default function handleWonBets({ WinningBets, }, state) {
  return of(
    game.set({
      gameResults: {
        ...gameResultsSelector(state),
        winningBets: WinningBets,
      },
    })
  );
}
