import { createSelector, } from 'reselect';
import { prop, } from 'ramda';
import { selectors, } from '@ezugi/bootstrap';

const { betsSelector, } = selectors;

export const currentBetsSelector = createSelector(
  betsSelector,
  prop('current')
);

export const betsHistorySelector = createSelector(
  betsSelector,
  prop('history')
);

export const lastBetsSelector = createSelector(betsSelector, prop('last'));
